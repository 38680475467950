<template>
  <v-container fluid>
    <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <v-card-title class="pa-4 accent">
          <v-btn
            :to="{ path: '/networkings' }"
            small
            exact
            fab
            text
            class="mr-2"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-icon color="primary" left>mdi-was</v-icon>
          Adicionar Networking
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <h1 class="pa-4 text-h5 font-weight-bold">Dados Gerais</h1>

          <v-divider class="mb-6"></v-divider>

          <v-row dense class="px-4">
            <!-- Usuario Networking -->
            <v-col cols="12" md="4">
              <v-combobox
                v-model="selectedUser"
                label="Usuário"
                dense
                :items="usuarios"
                item-text="nome"
                item-value="id"
                required
                :rules="formRules"
                :loading="loadingUsuarios"
              ></v-combobox>
            </v-col>

            <v-col cols="12" md="4">
              <v-combobox
                :disabled="!selectedUser"
                v-model="selectedNetwork"
                label="Network"
                dense
                :items="network_usuarios"
                item-text="nome"
                item-value="id"
                required
                :rules="formRules"
                :loading="loadingUsuarios"
              ></v-combobox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-btn text :to="{ path: '/networkings' }" exact color="primary">
            <v-icon class="mr-2" left>mdi-arrow-left</v-icon>
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="createNetworking"
            :disabled="!validForm"
            color="xbColor"
            class="white--text"
          >
            <v-icon left>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { all as fetchUsuarios } from "@/api/admin/usuarios.js";
import { inputRequired } from "@/plugins/utils.js";
import { create as postNetworking } from "@/api/admin/networking.js";

export default {
  name: "NovoNetworking",

  data() {
    return {
      networking: {},
      loadingUsuarios: false,
      loading: false,
      validForm: true,
      formRules: [inputRequired],
      usuarios: [],
      network_usuarios: [],
      selectedUser: null,
      selectedNetwork: null,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      disabledItems: ["Buzz"],
    };
  },

  watch: {
    selectedUser() {
      if (this.selectedUser) {
        let indexUsuario = this.network_usuarios.findIndex(
          (item) => item.id === this.selectedUser.id
        );

        this.network_usuarios = this.network_usuarios.map((item, index) => {
          if (index === indexUsuario) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
          return item;
        });
      }
      this.networking.user_id = this.selectedUser.id;
    },
    selectedNetwork() {
      this.networking.networking_user_id = this.selectedNetwork.id;
    },
  },
  computed: {},

  methods: {
    getUsuarios() {
      this.loadingUsuarios = true;
      fetchUsuarios(this.url)
        .then((response) => {
          this.usuarios = response;
          this.loadingUsuarios = false;
        })
        .catch(() => {
          this.loadingUsuarios = false;
        });
    },

    getNetworkUser() {
      this.loadingUsuarios = true;
      fetchUsuarios(this.url)
        .then((response) => {
          this.network_usuarios = response;
          this.loadingUsuarios = false;
        })
        .catch(() => {
          this.loadingUsuarios = false;
        });
    },

    createNetworking() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let networking = {};
        networking.user_id = this.networking.user_id;
        networking.networking_user_id = this.networking.networking_user_id;

        postNetworking(networking)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Criado com sucesso");
              this.loading = false;
              this.$router.push({ path: "/networkings" });
            }
            if (response.status === 406) {
              this.$toast.error("Networking ja cadastrado para este usuario");
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },

  mounted() {
    this.getUsuarios();
    this.getNetworkUser();
  },
};
</script>

<style></style>
